const baseStrings = {
	translation: {
		home: "Startseite",
		menu: "Speisekarte",
		opportunity: "speziell",
		opportunityProducts: "Spezialangebote",
		payment: "Zahlung",
		back: "hinten",
		yourName: "dein Name",
		roomNumber: "Zimmernummer ",
		deliveryInfo: "Gastinformationen ",
		yourDeliveryInfo: "Gastinformationen",
		paymentInfo: " Zahlungsinformationen ",
		productTotal: " Gesamtsumme der Produkte ",
		serviceFee: " Servicegebühr ",
		trayCharge: "Tablettgebühr",

		total: " Gesamtbetrag ",
		online: "Online",
		card: " Kreditkarte",
		myCart: " meinWarenkorb",
		cash: " Bargeld",
		note: "Not",
		delivery: " Lieferung ",
		payment: " Zahlung",
		orderNote: " Geben Sie Ihre Bestellnotiz ein ",
		completeOrder: " Bestellung abschließen ",
		enjoyYourMeal: " Guten Appetit ",
		yourOrderReceived: " Ihre Bestellung wurde erhalten ",
		// completeOrder: " Ihre Bestellung wurde abgeschlossen. ",
		hello: " Hallo ",
		uuiError: " Bitte scannen Sie den QR-Code, um das Menü anzuzeigen",
		successful: " Erfolgreich ",
		productAddToCart: " Produkt wurde dem Warenkorb hinzugefügt ",
		addToBasket: " In den Warenkorb legen ",
		removeFromBasket: " Aus dem Warenkorb entfernen ",
		productTitle: " PERSÖNLİCHE PFLEGE",
		itemNotFound: " Artikel nicht gefunden ",
		completeTheOrder: " Bitte schließen Sie die Bestellung ab ",
		selectThePayment: "Bitte wählen Sie Ihre Zahlungsmethode",
		paymentType: " Zahlungsmethode ",
		orderLimitAuthor: " Bestellgrenze {{autor}} {{Währung}}",
		orderLimit: " Bestellgrenze",
		dataNotFound: " Daten nicht gefunden",
		confirmOrder: " Zum Abschließen der Bestellung tippen Sie bitte",
		exchangeInfo: " Wenn Sie in ausländischer Währung bezahlen, wird Ihnen der Wechsel in Türkische Lira gegeben.",
		deliveryInfo:
			" Bitte stellen Sie sicher, dass Sie Ihren Namen und Ihre Zimmernummer korrekt und vollständig angeben, damit Ihre Bestellung ordnungsgemäß zugestellt werden kann.",
		weather: " Wetterbedingungen",
		totalEuro: " Gesamtbetrag (Euro)",
		totalDollar: " Gesamtbetrag (Dollar)",
		totalTL: " Gesamtbetrag (TL)",
		diss: " Sonderangebot für alle Produkte heute!",
		continue: " Weiter ",
		accept: " Ich akzeptiere die Fernabsatzvertrag ",
		done: " Ihre Bestellung wird vorbereitet...",
		enjoyMeal: " Guten Appetit ",
		dk: "MIN (Minute)",
		deliveryAndPayment: " Lieferung und Zahlung ",
		cashCurrency: "Währung wählen",
		continue: "weitermachen",
		other: "Andere Kategorien",

		// preparing: "Ihre Bestellung wird vorbereitet. Guten Appetit...",
		preparing: "Danke! Wir haben Ihre Bestellung erhalten. Bitte vergessen Sie nicht, Ihr Postfach für die Details zu überprüfen.",

		notValid: "Geben Sie Name und Zimmernummer ein",

		searchHome: "Suchen ...",
		notfound: "Daten nicht gefunden",
		"back-to-menu": "Zurück zum Menü",
		bankErrorTitle: "Zahlung fehlgeschlagen",
		bankErrorDescription: "Bei der Zahlung ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Karteninformationen und versuchen Sie es erneut",
		minimumCart: "minimum : {{author}}",
		modalLegalAgeTitle: "Sind Sie alt genug, um Artikel mit Altersbeschränkung zu kaufen?",
		modalLegalAgeText:
			"Artikel mit Altersbeschränkung (einschließlich Alkohol und Tabak) dürfen nicht an Personen unter 18 Jahren verkauft werden. Möglicherweise müssen Sie bei Erhalt Ihrer Bestellung einen gültigen Ausweis vorlegen",
		modalLegalAcceptButton: "Ja, ich bin 18 oder älter",
		cancel: "stornieren",
		medicineTitle: "Für schmerzlindernde Produkte gilt ein Limit von 2 pro Zählung",
		phoneNumber: "Telefonnummer",
		phoneNumberDelivery: "Telefonnummer:Unsere Lieferung ruft Sie an",
		phoneNumberCourier: "Telefonnummer: Unser Kurier ruft Sie an",
		useDiscountCode: "Verwenden Sie Ihren Rabattcode",
		discount: "Rabatt",
		discountAuthor: "Rabatt ({{author}})",
		products: "Produkte",
		append: "Anhängen",
		code: "Code",
		checkAuthor: "überprüfe {{author}}",
		"discount-note": "Sie können Ihren Rabattcode in diesem Abschnitt überprüfen und bei Bedarf verwenden",
		"discount-code-details":
			"Der von Ihnen angegebene Code ist {{code}}. Der Name dieses Rabattcodes ist {{name}} und er hat einen Wert von {{amount}} % Rabatt",
		"back-to-menu": "Zurück zum Menü",
		bankErrorTitle: "Zahlung fehlgeschlagen",
		bankErrorDescription: "Bei der Zahlung ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Karteninformationen und versuchen Sie es erneut",
		minimumCart: "minimum : {{author}}",
		orderNow: "Jetzt bestellen",
		AppointmentDaySelection: "Auswahl des Termintages",
		email: "E-Mail",
		apply: "Anwenden",
		allergyText: `Lebensmittel können Allergene enthalten oder mit ihnen in Kontakt kommen. Bitte geben Sie eventuelle Lebensmittelallergien im Feld "Anmerkungen" bei der Kasse an`,
		notePlaceholder: "Lebensmittel können Allergene enthalten oder mit ihnen in Kontakt kommen. Bitte geben Sie eventuelle Lebensmittelallergien an!",
		orderNumberDoneAuthor: "Bestellnummer: {{author}}",
		paymentPhoneNumberInfo:
			"Durch die Angabe Ihrer Telefonnummer erlauben Sie uns, Sie zu kontaktieren, falls wir Fragen zur Produktlieferung, dem Versandprozess oder unerwarteten Situationen haben.",
		paymentEmailInfo:
			"Durch die Angabe einer gültigen E-Mail-Adresse hier können Sie auf Ihre Bestelldetails, Rechnungen und Informationen zu unseren Kampagnen zugreifen.",
	},
};

export const de = baseStrings;
