import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Heart } from "./heart.svg";
import { ReactComponent as HeartFill } from "./heart-fill.svg";
import { ReactComponent as InfoCircle } from "./info-circle.svg";

const Icons = {
	Search,
	Heart,
	HeartFill,
	InfoCircle,
};

export default Icons;
