import { getHotel, getHotelMenu, getRecommendedProduct } from "api/api";
import ErrorTime from "components/ErrorTime";
import ProductCard from "components/RecommendedProductCard";
import { useState, useEffect, useMemo } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import FindMe from "reuse/FindMe";
import swal from "sweetalert";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import { pageView } from "../../GTM";
import styles from "./index.module.scss";

const RecommandedProduct = ({ catData }) => {
	const [pagination, setPagination] = useState({
		limit: 5,
		page: 1,
		sort: "asc",
	});

	// const [stateData, setStateData] = useState({
	// 	result: [],
	// 	total: 0,
	// });
	const { t, i18n } = useTranslation();
	const { addCount, cart, removeItem, addCart } = useCarts();
	const { data: uuid } = useUuid();
	// const handleSuccess = (data) => {
	// 	let newData = [...stateData?.result];
	// 	newData = [...newData, ...data.result];
	// 	console.log({ newData }, data.result);
	// 	setStateData({ result: newData, total });
	// };

	// const { data, isError, error } = useQuery("recommended", () => getRecommendedProduct(uuid));
	const { data, isError, error, isLoading, isFetching, refetch } = useQuery(
		["recommended", pagination],
		() => getRecommendedProduct(uuid, pagination)
		// { cacheTime: 0, onSuccess: handleSuccess }
	);
	const total = data?.total;
	const maxPage = Math.ceil(total / pagination?.limit);

	console.log({ recommendedData: data });
	const handleRemoveCart = (param) => {
		removeItem(param);
		// swal(t("successful"), t("removeFromBasket"), "success");
	};

	const handleAddCart = (param) => {
		addCart(param);
		console.log({ param });
		// swal(t("successful"), t("productAddToCart"), "success");
	};

	const handleNextPage = (props) => {
		const nextPage = pagination.page + 1;
		if (pagination?.page < maxPage) setPagination((p) => ({ ...p, page: nextPage }));
	};

	useEffect(() => {
		pageView(window?.location?.pathname);
		console.log("pathName", window.location.pathname);
	}, []);

	const filterData = useMemo(() => {
		return data?.filter((x) => !cart?.object[x?.id]);
	}, [cart, data]);

	return !isLoading && !filterData?.length ? (
		<ErrorTime message={t("dataNotFound")} className={"mt-0"} />
	) : (
		<section
		// className={styles.box}
		// style={{ overflowY: "hidden" }}
		>
			<div className="container-fluid">
				<h4 className={["text-start d-flex mt-2", styles.title].join(" ")}>{t("opportunityProducts")}</h4>
				<div className="row " style={{ overflowX: "auto" }}>
					<div
						className="d-flex flex-nowrap mt-0"
						style={{
							//  padding: "21px",
							marginBottom: "15px",
							// marginTop: "20px",
							// overflowY: "hidden"
						}}>
						{/* {filterData?.map((x) => { */}
						{(filterData || new Array(5).fill({}))?.map((x) => {
							const isToCart = cart?.object[x.id];
							return (
								<ProductCard
									// {...{ handleRemoveCart, handleAddCart, isToCart }}
									{...{ handleRemoveCart, handleAddCart, isToCart, isLoading: isFetching }}
									data={x}
									key={x?.name}
								/>
							);
						})}
					</div>
				</div>
				{/* {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : stateData?.result?.length ? (
          <FindMe callback={handleNextPage} margin={"150px"} />
        ) : null} */}
			</div>
		</section>
	);
};

export default RecommandedProduct;
