import React, { useState, createContext, useEffect, useRef, useCallback, useMemo } from "react";
import swal from "sweetalert";
import axios from "axios";
import { API_URL } from "../../config";
import { LANGUAGE_DATA_OBJECT, SYMBOLS_DATA_OBJECT, UUID_KY } from "enumeration";
import { getHotel, getHotelMenu, getHotelMenuWithBanner } from "api/api";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useCarts } from "_context/shoppingCart/cartProvider";
import BottomNav from "components/bottomNav";
import { useUuid } from "_context/uuid/uuidProvider";
import { Badge, Button, Spinner } from "react-bootstrap";
import ProductCard from "components/ProductCard";
import OtherCard from "components/OtherCard";
import { useCategory } from "_context/category/provider";
import ErrorTime from "components/ErrorTime";
import FindMe from "reuse/FindMe";

import PageNavbar from "components/PageNavbar/pageNavbar";
import CategorySlider from "./CategorySlider";
import WrapperProduct from "./WrapperProduct";
import "./style.scss";
import SearchBar from "./SearchBar";
import useQueryString from "hooks/useQueryString";
import styles from "./product.module.scss";

const Products = () => {
	// let [searchParams] = useSearchParams();
	// const uuid = searchParams.get(UUID_KY)
	const [pagination, setPagination] = useState({
		limit: 5,
		page: 1,
	});

	const queryString = useQueryString();

	// const [stateData, setStateData] = useState({
	//   result: [],
	//   total: 0,
	// });

	// const [category, setCategory] = useState([]);
	const [other, setOther] = useState([]);
	const [allCategories, setAllCategories] = useState();

	const handleSuccess = (data) => {
		console.log({ data });
		let object = [];

		// object.push(data.result.category?.[0]?.product?.category)

		data?.forEach?.((x) => {
			object.push(x.categoryData);
		});
		console.log({ object });
		setAllCategories(object);
		// setCategory(data.result.category);
		setOther(data);
	};
	// ------ -----------------------------------------------------------------
	const { data: uuid } = useUuid();
	let { id, bannerId } = useParams();

	const { data: hotel } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
	});

	const menuCurrency = hotel?.region?.menuCurrency;
	let symbol = SYMBOLS_DATA_OBJECT[menuCurrency]?.label;

	const menuRequest = useQuery(
		[`hotel-menu-${uuid}`, { search_text: queryString.search }],
		() => getHotelMenu(uuid, id, { search_text: queryString.search }),
		{
			// cacheTime: 0,
			cacheTime: 120000,

			// onSuccess: handleSuccess,
			enabled: !!id,
			staleTime: 120000,
			retry: false,
		}
	);

	const bannerMenuRequest = useQuery(
		[`banner-hotel-menu-${uuid}-${bannerId}`, { search_text: queryString.search }],
		() =>
			getHotelMenuWithBanner(
				uuid,
				bannerId
				// { ...pagination, search_text: queryString.search }
			),
		{
			cacheTime: 120000,
			// onSuccess: handleSuccess,
			enabled: !!bannerId,
			staleTime: 120000,
			retry: false,
		}
	);

	let { data, isError, error, refetch, isLoading } = id ? menuRequest : bannerMenuRequest;

	useEffect(() => {
		if (data?.length && !allCategories?.length) {
			handleSuccess(data);
		}
	}, [data]);

	// const allParams = {
	//   allCategories: data?.result?.map((x) => x.categoryData),
	//   other: data?.result
	// }
	// const { other, allCategories } = allParams || {}

	const { t, i18n } = useTranslation();

	const { data: catData } = useCategory();

	const total = data?.total;
	const maxPage = Math.ceil(total / pagination?.limit);
	// ------------------------------------------------------------------------

	const handleNextPage = (props) => {
		const nextPage = pagination.page + 1;
		if (pagination?.page < maxPage) setPagination((p) => ({ ...p, page: nextPage }));
	};

	// console.log({ error, data, category, other });
	// console.log("catData ===>", { catData });

	const isTranslate = {};
	const isTranslateChild = {};
	const currentCategory = catData?.parent;
	// console.log("catx ====>", item);
	catData?.parent?.translations?.forEach((x) => (isTranslate[x?.language] = x));
	catData?.child?.translations?.forEach((x) => (isTranslateChild[x?.language] = x));

	const textTranslate = isTranslate[i18n?.language];
	const textTranslateChild = isTranslateChild[i18n?.language];
	// console.log("cat textTranslate", textTranslateChild);
	const currentTranslate = catData?.parent ? textTranslate : textTranslateChild;

	const translatedCategoryObject = useMemo(() => {
		return allCategories?.reduce((prev, curr) => {
			prev[curr.id] = curr?.translations?.find((x) => x.language === i18n?.language)?.name;
			return prev;
		}, {});
	}, [allCategories]);

	// console.log({ hotelMenu: data?.result, catData, translatedCategoryObject, allCategories, currentCategory });
	// data.result.other

	// const isTranslateById = {};
	// allCategories?.forEach((x) => (
	//   isTranslateById[x?.language] = x
	//   ));
	// const textTranslateById = isTranslate[i18n?.language];

	// const memoProducts = useMemo(() => {
	//   return <WrapperProduct {...{
	//     other,
	//     translatedCategoryObject, symbol
	//   }} />
	// }, [other, translatedCategoryObject, symbol])
	return (
		<div className="relative">
			{/* <SupsisEmbedCode /> */}
			<div className={styles.sticky}>
				<PageNavbar
					text={
						// textTranslate?.name || catData?.parent?.name
						"menu"
					}
					path={catData?.child?.id ? `/child/${catData?.parent?.id}` : "/"}
				/>
				<SearchBar />

				<CategorySlider data={allCategories} translatedCategoryObject={translatedCategoryObject} currentCategory={currentCategory} />
			</div>
			{
				// !category ? (
				//   <ErrorTime message={t("dataNotFound")} />
				// ) :
				<section className="category category-p pb-3">
					<div className="container-fluid">
						{isError ? (
							<ErrorTime message={error?.response?.data?.message} />
						) : // category?.length || other?.length
						true ? (
							<>
								{/* <div className="row  g-4 mb-4">
                  {
                    (console.log("beforeMap", category),
                      category?.map((x) => {
                        const { product, id, ...other } = x;
                        const params = {
                          menuId: id,
                          ...product,
                          ...other,
                        };
                        const isToCart = cart?.object[params?.id];
                        if (isToCart) {
                          const shoppingItem = cart?.shoppingCart?.find(
                            (o) => o?.data?.id === params?.id
                          );
                          console.log(
                            "Main DEBUG",
                            cart,
                            shoppingItem,
                            "asdasdas2d"
                          );
                          params["count"] = shoppingItem?.count;
                        }
                        return (
                          <ProductCard
                            key={id}
                            {...{
                              handleRemoveCart,
                              handleAddCart,
                              isToCart,
                              data: params,
                            }}
                          />
                        );
                      }))
                  }
                </div> */}

								{/* {data?.result?.category?.length != 0 && (
                  <div>
                    <h4 className="other">{t("other")}</h4>
                  </div>
                )} */}
								{/* <div className=" g-4 mb-4">

                </div> */}
								{/* {memoProducts} */}
								<WrapperProduct
									{...{
										other,
										translatedCategoryObject,
										symbol,
									}}
								/>
							</>
						) : (
							""
						)}

						{/* <div className={"loader"}>
              {isLoading ? (
                <div className="text-center">
                  {" "}
                  <Spinner animation="border" />
                </div>
              ) : (
                ""
              )}
            </div> */}
					</div>
				</section>
			}
			{<div className="empty-space-cat" />}
			<BottomNav />
		</div>
	);
};

export default Products;
