const baseStrings = {
	translation: {
		home: " Главная страница ",
		menu: " Меню ",
		opportunity: " Специальный ",
		opportunityProducts: " Специальные предложения ",
		payment: " Оплата ",
		back: "Назад",
		yourName: " Ваше имя ",
		roomNumber: " Номер комнаты ",
		deliveryInfo: " Информация для гостей  ",
		yourDeliveryInfo: " Информация для гостей  ",
		paymentInfo: " Информация о платежах ",
		productTotal: " Итого по продукту ",
		serviceFee: " Сервисный сбор ",
		trayCharge: "Плата за поднос",

		total: " Общая сумма ",
		online: "Онлайн",
		card: " Кредитная карта  ",
		myCart: " Моя корзина ",
		cash: " Наличные ",
		note: " Примечание ",
		delivery: " Доставка ",
		payment: " Оплата ",
		orderNote: " Введите примечание к заказу ",
		completeOrder: " Полный заказ ",
		enjoyYourMeal: " Приятного аппетита!",
		yourOrderReceived: " Ваш заказ получен ",
		// completeOrder: " Ваш заказ завершен ",
		hello: " Здравствуйте. ",
		uuiError: " Для участия в конкурсе достаточно отсканировать QR-код.",
		successful: " Успешный ",
		productAddToCart: " Товар добавлен в корзину!",
		addToBasket: " Добавить в корзину ",
		removeFromBasket: " Удалить из корзины ",
		productTitle: " ПЕРСОНАЛЬНЫЙ УХОД ",
		itemNotFound: " Товар не найден ",
		completeTheOrder: " Пожалуйста, заполните заказ ",
		selectThePayment: " Пожалуйста, выберите тип оплаты ",
		paymentType: " Способ оплаты ",
		orderLimitAuthor: " Order Limit {{author}} {{currency}}",
		orderLimit: " Лимит заказа ",
		dataNotFound: " Данные не найдены ",
		confirmOrder: " Нажмите, чтобы завершить заказ ",
		exchangeInfo: " Если вы платите в иностранной валюте, то сдача выдается в турецких лирах",
		deliveryInfo:
			"  Пожалуйста, убедитесь, что Вы полностью написали информацию о своем имени и номере комнаты, чтобы Ваш заказ был доставлен правильно...",

		totalEuro: " Tобщая сумма (евро)",
		totalDollar: " Общая сумма (долл.)",
		totalTL: " Общая сумма (TL)",
		diss: " Скидка на все товары Специально для сегодняшнего дня!",

		continue: "Продолжить",
		cashCurrency: "Выберите валюту",
		other: "Другие категории",

		// preparing: "Ваш заказ готовится Наслаждайтесь едой...",
		preparing: "Спасибо! Мы получили ваш заказ. Пожалуйста, не забудьте проверить свою почту для получения подробностей.",

		notValid: "Введите имя, фамилию и номер комнаты",

		deviceError: "Разрешен размер экрана планшета или мобильного устройства. Допустимый размер страницы - менее 768 пикселей",
		searchHome: "Поиск ...",
		notfound: "Данные не найдены",
		"back-to-menu": "Вернуться в меню",
		bankErrorTitle: "Ошибка платежа",
		bankErrorDescription: "Проблема с оплатой. Пожалуйста, проверьте данные вашей карты и повторите попытку",
		minimumCart: "минимум : {{author}}",
		modalLegalAgeTitle: "Вы достаточно взрослый, чтобы покупать предметы с возрастными ограничениями?",
		modalLegalAgeText:
			"Товары с ограничениями по возрасту (включая алкоголь и табак) не продаются лицам младше 18 лет. При получении заказа вам может потребоваться предоставить действительное удостоверение личности",
		modalLegalAcceptButton: "да, мне 18 лет или больше",
		cancel: "отмена",
		medicineTitle: "Обезболивающие средства имеют ограничение - 2 на единицу",
		phoneNumber: "номер телефона",
		phoneNumberDelivery: "Номер телефона: Вам перезвонит наша служба доставки",
		phoneNumberCourier: "Номер телефона: Вам позвонит наш курьер",
		useDiscountCode: "Используйте свой код скидки",
		discount: "Скидка",
		discountAuthor: "Скидка ({{author}})",
		products: "Продукты",
		append: "Добавить",
		code: "Код",
		checkAuthor: "проверьте {{author}}",
		"discount-note": "Вы можете проверить свой код скидки в этом разделе и использовать его при необходимости",
		"discount-code-details": "Вы предоставили код — {{code}}. Имя этого кода скидки — {{name}}, и его стоимость составляет {{amount}} % скидки",
		"back-to-menu": "Вернуться в меню",
		bankErrorTitle: "Ошибка платежа",
		bankErrorDescription: "Проблема с оплатой. Пожалуйста, проверьте данные вашей карты и повторите попытку",
		minimumCart: "минимум : {{author}}",
		orderNow: "Заказать сейчас",
		AppointmentDaySelection: "Выбор дня встречи",
		email: "электронная почта",
		apply: "Применить",
		allergyText: `Еда может содержать аллергены или контактировать с ними. Пожалуйста, укажите любые пищевые аллергии в разделе "Заметки" при оформлении заказа`,
		notePlaceholder: "Еда может содержать аллергены или контактировать с ними. Пожалуйста, укажите любые пищевые аллергии!",
		orderNumberDoneAuthor: "Номер заказа: {{author}}",
		paymentPhoneNumberInfo:
			"Предоставляя свой номер телефона, вы разрешаете нам связаться с вами в случае возникновения вопросов по поставке товара, процессу доставки или в непредвиденных ситуациях.",
		paymentEmailInfo:
			"Предоставляя здесь действующий адрес электронной почты, вы сможете получить доступ к данным о вашем заказе, счетам и информации о наших кампаниях.",
	},
};

export const ru = baseStrings;
