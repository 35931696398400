import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TooltipComponent = ({ text, children }) => {
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{text}
		</Tooltip>
	);
	return (
		<OverlayTrigger trigger="hover" placement="top" overlay={renderTooltip}>
			<div>{children}</div>
		</OverlayTrigger>
	);
};

export default TooltipComponent;
